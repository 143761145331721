@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400&display=swap");

/** GLOBAL **/

body,
#root {
  font-family: "Mitr", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f2f2f2;
}

button {
  padding: 0.5rem 1rem;
  font-family: "Mitr", sans-serif;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 0.25rem;
}

button:hover,
.iconButton:hover {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.aboutRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.redButton {
  background-color: #f25f5c;
  color: #fff;
  font-size: 1.3rem;
}

.redButton:hover {
  background-color: #bd120f;
}

.whiteButton {
  background-color: #fff;
  color: #247ba0;
  font-size: 1.3rem;
}

.whiteButton:hover {
  background-color: #f2f2f2;
}

.appIconRow {
  margin: 1rem;
  gap: 1rem;
}

.iconButton {
  padding: 0.5rem;
  line-height: 0;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  background-color: #70c1b3;
  color: #fff;
  font-size: 2rem;
}

.iconButton:hover {
  background-color: #378174;
}

/** HEADLINER **/
.headerContainer {
  text-align: center;
  padding: 50px 0;
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.headline,
.subheadline {
  opacity: 0;
  animation: fadeInUp 2s ease-in-out forwards;
}

.headline {
  color: #343a40;
  font-size: 2.5em;
  margin-bottom: 20px;
  animation-delay: 0.5s;
}

.nameHighlight {
  color: #70c1b3;
}

.subheadline {
  color: #6c757d;
  font-size: 1.5em;
  margin-bottom: 30px;
  animation-delay: 1s;
}

.aboutLink {
  text-decoration: none;
}

.aboutMeButton {
  font-size: 1.2em;
  color: #fff;
  background-color: #70c1b3;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  transition: all 0.3s ease;
  animation: bounceIn 2s ease-in-out forwards;
  animation-delay: 1.5s;
  opacity: 0;
}

.aboutMeButton:hover {
  background-color: #378174;
  transform: scale(1.1);
}

/** ABOUT **/
#aboutMe,
#portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 1rem;
}

#aboutMe h2,
#portfolio h2 {
  color: #247ba0;
  font-size: 5rem;
}

.aboutMeImage img {
  height: calc(220px + 4vw);
  width: calc(140px + 4vw);
  border-radius: 5%;
}

.aboutMeText {
  width: 50%;
}

.aboutMeText p {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

.aboutMeText p a {
  color: #247ba0;
  font-weight: 400;
  text-decoration: none;
}

.aboutMeText p a:hover {
  text-decoration: underline;
}

/** PROJECTS **/
#portfolio > div {
  width: 100%;
  margin-top: 2rem;
}
.outerProjectContainer {
  margin: 2rem 1.5rem;
}
.projectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #FFF;
}

.projectContainer h3 {
  font-size: 2rem;
  color: #f25f5c;
}

.projectContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
}

.projectContent p {
  text-align: left;
  font-weight: 300;
}

.projectContent h4 {
  color: #70c1b3;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.showMore {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
}

.showMore h4 {
  cursor: pointer;
  color: #247ba0;
}

.showMore p {
  text-align: left;
  line-height: 1.8;
  width: 100%;
  white-space: pre-line;
}

.projectImage img {
  height: calc(240px + 4vw);
  width: 90%;
  border-radius: 0.45rem;
}

.projectRole {
  color: #247ba0;
  font-size: 1.2rem;
}

.isProjectOnly {
  background-color: #247ba0;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #fff;
  border-radius: 0.25rem;
}

.lazyload-wrapper .projectContainer {
  -webkit-animation: slide-in-fwd-bottom 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.projectHeader {
  margin-bottom: 1rem;
}

.projectPrizes {
  text-align: left;
  font-weight: 300;
}

.interestingThingsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1),
    0 10px 10px -10px rgba(0, 0, 0, 0.2); /* Additional shadow for lift effect */
  animation: fadeIn 1.5s ease-in;
  margin-top: 2rem;
}

.interestingThingsHeader {
  color: #343a40;
  font-size: 2em;
  margin-bottom: 20px;
  text-transform: uppercase;
  animation: fadeInDown 2s ease-in-out forwards;
}

.interestingThingsText {
  color: #6c757d;
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
  max-width: 80%;
  animation: fadeInUp 2s ease-in-out forwards;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.randomFactButton {
  font-size: 1em;
  color: #fff;
  background-color: #f25f5c;
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  animation: bounceIn 2s ease-in-out forwards;
  cursor: pointer;
}

.randomFactButton:hover {
  background-color: #bd120f;
  transform: scale(1.1);
}

.projectPrizes ul li {
  list-style-type: disc;
  margin-left: 1rem;
}

/** MEDIA QUERIES **/
@media only screen and (max-width: 850px) {
  .aboutMeText p {
    font-size: 1rem;
  }

  header {
    padding: 0.5rem;
    align-items: center;
  }
  header h1 {
    font-size: 3rem;
  }

  #aboutMe,
  #portfolio {
    margin: 0.5rem;
  }

  .lazyload-wrapper {
    width: 100%;
  }

  .aboutMeText {
    width: 95%;
  }

  .projectContainer {
    padding: 0;
  }

  .projectContent {
    width: 90%;
  }

  #aboutMe h2,
  #portfolio h2 {
    font-size: 2.5rem;
    margin: 1rem 0;
  }
}

/* Keyframes for animations */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translateY(50px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.05);
  }
  70% {
    opacity: 0.7;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/** KEYFRAMES **/
@-webkit-keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
    transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
    transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
